<template>
  <div class="useportrait">
    <div class="hn_titlecontent">
      <div class="hn_tt_titlecontent">
        <div class="hn_tt_tt_tagline"></div>
        <div class="hn_tt_tt_text">用户画像</div>
      </div>
      <div class="hn_tt_line">
        <dv-decoration-2 style="width: 24.7396vw; height: 0.2604vw" />
      </div>
    </div>
    <div class="ut_content">
      <div class="ut_ct_tabslist">
        <div
          class="ut_ct_tt_item"
          :class="{ ut_ct_tt_activeitem: activecurrent == index }"
          v-for="(item, index) in tabslist"
          :key="index"
          @click="changeactive(index)"
        >
          <div class="ut_ct_tt_im_name">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="ut_ct_echarts">
        <div id="chart_0" style="width: 470px; height: 280px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "",
  components: {},
  props: {},
  props: {
    crowddata: {
      type: Array,
      default() {
        return [];
      },
    },
    crowd1data: {
      type: Array,
      default() {
        return [];
      },
    },
    crowd2data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      tabslist: [
        {
          name: "人群分布",
        },
        {
          name: "8大人群",
        },
        {
          name: "地区分布 tops",
        },
      ],
      activecurrent: 0,
      crowdlist: [],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    if (this.crowd1data) {
      setTimeout(() => {
        this.crowdlist = this.crowd1data;
        this.initChart();
      }, 800);
    }
  },
  methods: {
    changeactive(index) {
      this.activecurrent = index;
      this.crowdlist =
        index == 0
          ? this.crowd1data
          : index == 1
          ? this.crowddata
          : this.crowd2data;
      this.initChart();
    },
    initChart() {
      const chart = echarts.init(document.getElementById("chart_0"));
      const option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          bottom: "0%",
          left: "left",
          icon: "circle",
          textStyle: {
            color: " rgba(255,255,255,0.65)",
            fontSize: "0.6771vw",
          },
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: this.crowdlist,
          },
        ],
      };
      chart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.useportrait {
  .hn_titlecontent {
    .hn_tt_titlecontent {
      display: flex;
      align-content: center;
      .hn_tt_tt_tagline {
        width: 4px;
        height: 23px;
        background: linear-gradient(180deg, #00ffdd 0%, #001c1a 100%);
      }
      .hn_tt_tt_text {
        font-size: 22px;
        color: #ffffff;
        height: 26px;
        line-height: 26px;
        margin-left: 12px;
      }
    }
    .hn_tt_line {
      margin-left: 3px;
      margin-top: 5px;
    }
  }
  .ut_content {
    .ut_ct_tabslist {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .ut_ct_tt_item {
        padding: 10px;
        margin-right: 10px;
        color: rgba(255, 255, 255, 0.5);

        .ut_ct_tt_im_name {
          font-size: 13px;
        }
      }
      .ut_ct_tt_activeitem {
        background: #2f261d;
        color: #eb9e56;
      }
    }
  }
}
</style>
