<template>
  <div id="app">
    <v-scale-screen width="1920" height="1080" :full-screen="false">
      <router-view />
    </v-scale-screen>

    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style lang="scss">
body {
  width: 100vw;
  margin: 0px;
  background: rgba(3, 3, 3, 1);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
  background: rgba(3, 3, 3, 1);
  margin: 0px;
  width: 100vw;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 40px);
}
.screen-box {
  // overflow: auto !important;
  // width: 1920px !important;
  // height: 1040px !important;
}
.screen-wrapper {
  // margin: 0 auto !important;
}
</style>
