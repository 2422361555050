<template>
  <div class="dealrate">
    <div class="hn_titlecontent">
      <div class="hn_tt_titlecontent">
        <div class="hn_tt_tt_tagline"></div>
        <div class="hn_tt_tt_text">用户成交率</div>
      </div>
      <div class="hn_tt_line">
        <dv-decoration-2 style="width: 24.7396vw; height: 0.2604vw" />
      </div>
    </div>
    <div class="de_content">
      <div class="de_ct_item" v-for="(item, index) in ratelist" :key="index">
        <img
          class="de_ct_im_img"
          :src="`https://oss-property.mytx139.com/bigscreen/rate_${index}.png`"
          alt=""
        />
        <div class="de_ct_im_content">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              height: 75px;
            "
          >
            <div>{{ item.name }}</div>
            <div>{{ item.nums }}人</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {
    ratedata: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      ratelist: [
        {
          name: "今日注册用户",
          nums: 0,
        },
        {
          name: "点击查询",
          nums: 0,
        },
        {
          name: "提交申请",
          nums: 0,
        },
        {
          name: "成功下款",
          nums: 0,
        },
      ],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    if (this.ratedata) {
      this.ratelist[0].nums = this.ratedata.cur_day_register;
      this.ratelist[1].nums = this.ratedata.cur_day_search;
      this.ratelist[2].nums = this.ratedata.cur_day_apply;
      this.ratelist[3].nums = this.ratedata.cur_day_get_money;
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.dealrate {
  .hn_titlecontent {
    .hn_tt_titlecontent {
      display: flex;
      align-content: center;
      .hn_tt_tt_tagline {
        width: 4px;
        height: 23px;
        background: linear-gradient(180deg, #00ffdd 0%, #001c1a 100%);
      }
      .hn_tt_tt_text {
        font-size: 22px;
        color: #ffffff;
        height: 26px;
        line-height: 26px;
        margin-left: 12px;
      }
    }
    .hn_tt_line {
      margin-left: 3px;
      margin-top: 5px;
    }
  }
  .de_content {
    margin-top: 19px;
    display: flex;
    flex-direction: column;
    .de_ct_item {
      position: relative;
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-start;
      .de_ct_im_img {
        height: 75px;
      }
      .de_ct_im_content {
        position: absolute;
        display: flex;
        justify-content: center;
        flex-direction: column;
        // width: 24.375vw;
        height: 75px;
        top: 0px;
        left: 10px;
        text-align: left;
        font-size: 12px;
        color: #ffffff;
      }
    }
  }
}
</style>
