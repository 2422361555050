<template>
  <div class="homeproduct">
    <div class="hn_titlecontent">
      <div class="hn_tt_titlecontent">
        <div class="hn_tt_tt_tagline"></div>
        <div class="hn_tt_tt_text">今日贷款产品排名</div>
      </div>
      <div class="hn_tt_line">
        <dv-decoration-2 style="width: 24.7396vw; height: 0.2604vw" />
      </div>
    </div>
    <div class="hn_rank">
      <div class="hn_rk_titlecontent">
        <div class="hn_rk_tt_item">
          <div class="hn_rk_tt_im_tag"></div>
          <div class="hn_rk_tt_im_text">申请总人数(人)</div>
        </div>
        <div class="hn_rk_tt_item" style="margin-left: 1.0938vw">
          <div class="hn_rk_tt_im_tag_s"></div>
          <div class="hn_rk_tt_im_text">放款总金额(元)</div>
        </div>
      </div>
      <div class="hn_rk_charts">
        <div
          class="hn_rk_cs_item"
          v-for="(item, index) in productdata"
          :key="index"
        >
          <div class="hn_rk_cs_im_text">{{ item.bank_name }}</div>
          <div class="hn_rk_cs_im_content">
            <div class="hn_rk_cs_im_ct_lv">
              <div
                class="hn_rk_cs_im_ct_lv_tag"
                :style="{ width: item.userwidth }"
              ></div>
              <div class="hn_rk_cs_im_ct_lv_text">{{ item.cur_day_user }}</div>
            </div>
            <div class="hn_rk_cs_im_ct_hg">
              <div
                class="hn_rk_cs_im_ct_hg_tag"
                :style="{ width: item.moneywidth }"
              ></div>
              <div class="hn_rk_cs_im_ct_hg_text">{{ item.cur_day_money }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    productdata: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.homeproduct {
  .hn_titlecontent {
    .hn_tt_titlecontent {
      display: flex;
      align-content: center;
      .hn_tt_tt_tagline {
        width: 4px;
        height: 23px;
        background: linear-gradient(180deg, #00ffdd 0%, #001c1a 100%);
      }
      .hn_tt_tt_text {
        font-size: 22px;
        color: #ffffff;
        height: 26px;
        line-height: 26px;
        margin-left: 12px;
      }
    }
    .hn_tt_line {
      margin-left: 3px;
      margin-top: 5px;
    }
  }
  .hn_rank {
    margin-top: 32px;
    .hn_rk_titlecontent {
      display: flex;
      align-items: center;
      .hn_rk_tt_item {
        display: flex;
        align-items: center;
        .hn_rk_tt_im_tag {
          width: 8px;
          height: 8px;
          background: #00ffdd;
          border-radius: 50%;
        }
        .hn_rk_tt_im_tag_s {
          width: 8px;
          height: 8px;
          background: #eb9e56;
          border-radius: 50%;
        }
        .hn_rk_tt_im_text {
          margin-left: 5px;
          font-size: 13px;
          color: rgba(255, 255, 255, 0.65);
          line-height: 16px;
        }
      }
    }
    .hn_rk_charts {
      margin-top: 41px;
      .hn_rk_cs_item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 30px;
        .hn_rk_cs_im_text {
          font-size: 13px;
          color: #ffffff;
        }
        .hn_rk_cs_im_content {
          display: flex;
          align-items: center;
          position: relative;
          margin-left: 7px;
          .hn_rk_cs_im_ct_lv {
            position: absolute;
            left: 0px;
            top: 0px;
            display: flex;
            align-items: center;
            .hn_rk_cs_im_ct_lv_tag {
              width: 47px;
              height: 10px;
              background: linear-gradient(90deg, #0a1d19 0%, #00f9b8 100%);
              border-radius: 20px;
            }
            .hn_rk_cs_im_ct_lv_text {
              font-size: 13px;
              color: #00ffdd;
            }
          }

          .hn_rk_cs_im_ct_hg {
            display: flex;
            align-items: center;
            .hn_rk_cs_im_ct_hg_tag {
              width: 269px;
              height: 10px;
              background: linear-gradient(90deg, #110a04 0%, #df9652 100%);
              border-radius: 20px;
            }
            .hn_rk_cs_im_ct_hg_text {
              font-size: 13px;
              color: #eb9e56;
            }
          }
        }
      }
      .hn_rk_cs_item:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
</style>
