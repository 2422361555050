import Vue from 'vue'
import App from './App.vue'
// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'
import VScaleScreen from 'v-scale-screen'
//引入
import echarts from 'echarts'
import router from "./router"
import axios from 'axios'
Vue.prototype.$ajax= axios
//使用
Vue.prototype.$echarts = echarts;
Vue.use(VScaleScreen)
Vue.use(dataV)
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
