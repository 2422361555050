// 导入vue
import Vue from 'vue'
// 导入vue-router
import VueRouter from 'vue-router'

// 导入路由规则中要是用的组件
import MyPage from '../pages/mypage.vue'
// vue使用router
Vue.use(VueRouter)
// 创建路由规则
const routes = [
  // 数组中放对象
  {
  // path - 指定路径
    path: '/',
    // component - 指定 当路径是/index的时候，显示哪个组件
    component: MyPage
  }
]

// 创建路由对象
const router = new VueRouter({
  // routes - 指定路由规则
  routes,
  // 路由模式 - hash/history
  mode: 'hash'
})

// 导出路由对象
export default router

