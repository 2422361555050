<template>
  <div class="homeloan">
    <div class="hn_titlecontent">
      <div class="hn_tt_titlecontent">
        <div class="hn_tt_tt_tagline"></div>
        <div class="hn_tt_tt_text">贷款用户数据-贷款金额</div>
      </div>
      <div class="hn_tt_line">
        <dv-decoration-2 style="width: 24.7396vw; height: 0.2604vw" />
      </div>
    </div>
    <div class="hn_content">
      <div class="hn_ct_top">
        <div class="hn_ct_tp_loancontent">
          <div class="hn_ct_tp_lt_title">今日贷款用户数据</div>
          <div class="hn_ct_tp_lt_number">
            {{ loandata.cur_day_user_count }}
          </div>
          <div class="hn_ct_tp_lt_tips">
            <span class="hn_ct_tp_lt_ts_text">{{
              loandata.cur_day_user_increase < 0 ? "同比下降" : "同比增长"
            }}</span>
            <span
              class="hn_ct_tp_lt_ts_tag_s"
              :class="{
                hn_ct_tp_lt_ts_tag: loandata.cur_day_user_increase > 0,
              }"
              >^</span
            >
            <span
              class="hn_ct_tp_lt_ts_num_s"
              :class="{
                hn_ct_tp_lt_ts_num: loandata.cur_day_user_increase > 0,
              }"
              >{{ Math.abs(loandata.cur_day_user_increase) }}%</span
            >
          </div>
        </div>
        <div class="hn_ct_tp_loancontent" style="margin-left: 1.6667vw">
          <div class="hn_ct_tp_lt_title">今日贷款总金额</div>
          <div class="hn_ct_tp_lt_number">
            {{ loandata.cur_day_money_count }}
          </div>
          <div class="hn_ct_tp_lt_tips">
            <span class="hn_ct_tp_lt_ts_text">{{
              loandata.cur_day_money_increase < 0 ? "同比下降" : "同比增长"
            }}</span>
            <span
              class="hn_ct_tp_lt_ts_tag_s"
              :class="{
                hn_ct_tp_lt_ts_tag: loandata.cur_day_money_increase > 0,
              }"
              >^</span
            >
            <span
              class="hn_ct_tp_lt_ts_num_s"
              :class="{
                hn_ct_tp_lt_ts_num: loandata.cur_day_money_increase > 0,
              }"
              >{{ Math.abs(loandata.cur_day_money_increase) }}%</span
            >
          </div>
        </div>
      </div>
      <!-- 表格 -->
      <div class="hn_ct_right">
        <!-- 表格头部 -->
        <!-- <div class="hn_ct_rt_top">
          <div class="hn_ct_rt_tp_items">
            <div class="hn_ct_rt_top_is_tag"></div>
            <div class="hn_ct_rt_top_is_text">昨日</div>
          </div>

          <div class="hn_ct_rt_top_itemrights">
            <div class="hn_ct_rt_top_is_tag_s"></div>
            <div class="hn_ct_rt_top_is_text">今日</div>
          </div>
        </div> -->
        <div style="display: flex">
          <div>
            <div id="chart" style="width: 200px; height: 230px"></div>
            <!-- <div>今日贷款用户数据</div> -->
          </div>
          <div>
            <div id="chart_s" style="width: 200px; height: 230px"></div>
            <!-- <div>今日贷款用户数据</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";

export default {
  props: {
    loanarr1: {
      type: Array,
      default() {
        return [];
      },
    },
    loanarr2: {
      type: Array,
      default() {
        return [];
      },
    },
    loandata: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  mounted() {
    setTimeout(() => {
      this.initChart();
    }, 800);
  },
  methods: {
    initChart() {
      const chart = echarts.init(document.getElementById("chart"));
      const chart1 = echarts.init(document.getElementById("chart_s"));
      const option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "0%",
          left: "left",
          icon: "circle",
          textStyle: {
            color: " rgba(255,255,255,0.65)",
            fontSize: "0.6771vw",
          },
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 13,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: this.loanarr1,
          },
        ],
      };
      const option1 = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          right: "0%",
          left: "left",
          icon: "circle",
          textStyle: {
            color: " rgba(255,255,255,0.65)",
            fontSize: "0.6771vw",
          },
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 13,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: this.loanarr2,
          },
        ],
      };
      chart.setOption(option);

      chart1.setOption(option1);
    },
  },
};
</script>
<style lang="scss">
.homeloan {
  .hn_titlecontent {
    .hn_tt_titlecontent {
      display: flex;
      align-content: center;
      .hn_tt_tt_tagline {
        width: 4px;
        height: 23px;
        background: linear-gradient(180deg, #00ffdd 0%, #001c1a 100%);
      }
      .hn_tt_tt_text {
        font-size: 22px;
        color: #ffffff;
        height: 26px;
        line-height: 26px;
        margin-left: 12px;
      }
    }
    .hn_tt_line {
      margin-left: 3px;
      margin-top: 5px;
    }
  }
  .hn_content {
    margin-top: 30px;
    .hn_ct_top {
      display: flex;
      justify-content: flex-start;
      .hn_ct_tp_loancontent {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .hn_ct_tp_lt_title {
          font-size: 16px;
          color: rgba(255, 255, 255, 0.5);
        }
        .hn_ct_tp_lt_number {
          max-width: 200px;
          font-weight: bold;
          font-size: 50px;
          color: #eb9e56;
          line-height: 58px;
          margin: 12px;
        }
        .hn_ct_tp_lt_tips {
          font-size: 13px;
          color: rgba(255, 255, 255, 0.5);

          .hn_ct_tp_lt_ts_text {
          }
          .hn_ct_tp_lt_ts_tag_s {
            color: #00ffdd;
            margin: 0 3px;
          }
          .hn_ct_tp_lt_ts_tag {
            color: #ff000b;
            margin: 0 3px;
          }
          .hn_ct_tp_lt_ts_num_s {
            color: #00ffdd;
            line-height: 15px;
          }
          .hn_ct_tp_lt_ts_num {
            color: #ff000b;
            line-height: 15px;
          }
        }
      }

      .hn_ct_tp_right {
      }
    }
  }
  .hn_ct_right {
    margin-top: 15px;

    font-size: 15px;
    color: rgba(255, 255, 255, 0.5);
    .hn_ct_rt_top {
      display: flex;
      align-items: center;
    }
    .hn_ct_rt_tp_items {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .hn_ct_rt_top_itemrights {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: 21px;
    }
    .hn_ct_rt_top_is_tag {
      width: 8px;
      height: 8px;
      background: #00ffdd;
      border-radius: 50%;
    }
    .hn_ct_rt_top_is_text {
      font-size: 13px;
      margin-left: 5px;
      color: rgba(255, 255, 255, 0.65);
    }
    .hn_ct_rt_top_is_tag_s {
      width: 8px;
      height: 8px;
      background: #eb9e56;
      border-radius: 50%;
    }
  }
}
</style>
