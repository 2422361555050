<template>
  <div class="homemap">
    <div class="hp_mapcontent">
      <dv-flyline-chart-enhanced
        :config="config"
        style="width: 700px; height: 622px"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeMap",
  components: {},
  props: {
    platdata: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      config: {
        points: this.platdata.list,
        lines: this.platdata.lines,
        text: {
          show: true,
          color: "#FFFFFF",
          fontSize: "0.05vw",
        },
        lineStyle: {
          color: "#ffffff", // 这里设置你想要的颜色
          width: 2,
        },
        bgImgSrc: "https://oss-property.mytx139.com/bigscreen/homemap.png?123",
      },
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
