<template>
  <div class="mypage">
    <!-- 头部 -->
    <div class="me_top">
      <img src="../assets/static/mypage/topbg.png" alt="" />
      <div class="me_tp_left">
        <img src="../assets/logo.png" alt="logo" />
        <span>数字钱庄</span>
      </div>
      <div class="me_tp_center">
        <img src="../assets/static/mypage/toptitleimg.png" alt="" />
      </div>
      <div class="me_tp_right">
        {{ currentDateTime }}
      </div>
    </div>
    <!-- 内容区域 -->
    <div class="me_content">
      <!-- 左边 -->
      <div class="me_ct_left">
        <!-- 贷款用户数据-贷款金额 -->
        <div class="me_ct_lt_loan">
          <home-loan
            :loanarr1="homedata.loanarr1"
            :loanarr2="homedata.loanarr2"
            :loandata="homedata.loan_user_money"
          ></home-loan>
        </div>
        <!-- 今日贷款产品排名 -->
        <div class="me_ct_lt_rank">
          <home-product
            :productdata="homedata.loan_product"
            v-if="homedata.loan_product"
          ></home-product>
        </div>
      </div>
      <!-- 中间 -->
      <div class="me_ct_center">
        <!-- 地图 -->
        <home-map :platdata="homedata.plat" v-if="homedata.plat"></home-map>
      </div>
      <!-- 右边 -->
      <div class="me_ct_right">
        <!-- 用户成交率 -->
        <div class="me_ct_rt_rate">
          <deal-rate
            :ratedata="homedata.user_transaction"
            v-if="homedata.user_transaction"
          ></deal-rate>
        </div>
        <!-- 用户画像 -->
        <div class="me_ct_rt_use">
          <use-portrait
            :crowddata="homedata.crowd"
            :crowd1data="homedata.crowd1"
            :crowd2data="homedata.crowd2"
            v-if="homedata.crowd"
          ></use-portrait>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeLoan from "../components/homeloan.vue";
import HomeProduct from "../components/homeproduct.vue";
import HomeMap from "../components/homemap.vue";
import DealRate from "../components/dealrate.vue";
import UsePortrait from "../components/useportrait.vue";
export default {
  components: {
    HomeLoan,
    HomeProduct,
    HomeMap,
    DealRate,
    UsePortrait,
  },
  data() {
    return {
      currentDateTime: "",
      intervalId: null,
      homedata: {}, //首页数据
    };
  },
  created() {
    this.getScreenData();

    this.updateDateTime();
    this.intervalId = setInterval(this.updateDateTime, 1000);
  },
  mounted() {},
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    getScreenData() {
      this.$ajax
        .post("https://property.djmr139.com/api/v1/bigScreen/ScreenData")
        .then((response) => {
          console.log(1111); //  处理成功的函数 相当于success
          console.log(response); //  处理成功的函数 相当于success
          this.homedata = response.data.data;
          // 贷款产品线长度
          let totalmoneywidth = 246;
          let totaluserwidth = 128;
          let productarr = response.data.data.loan_product;
          let moneyindex = productarr.findIndex((res) => {
            return res.is_Money_max == 1;
          });
          let userindex = productarr.findIndex((res) => {
            return res.is_user_max == 1;
          });
          let onemonywidth = (
            totalmoneywidth / productarr[moneyindex].cur_day_money
          ).toFixed(4);
          for (let i = 0; i < productarr.length; i++) {
            productarr[i].moneywidth =
              (
                (productarr[i].cur_day_money /
                  productarr[moneyindex].cur_day_money) *
                  totalmoneywidth +
                totaluserwidth
              ).toFixed(2) + "px";
            productarr[i].userwidth =
              (
                (productarr[i].cur_day_user /
                  productarr[userindex].cur_day_user) *
                totaluserwidth
              ).toFixed(2) + "px";
          }
          console.log("productarr", productarr);

          let data = response.data.data.loan_user_money;
          let loanarr1 = [];
          let loanarr2 = [];
          let dataloan1 = {
            name: "昨日贷款用户",
            value: data.yester_day_user_count,
          };
          let dataloan2 = {
            name: "今日贷款用户",
            value: data.cur_day_user_count,
          };
          let daydataloan1 = {
            name: "昨日贷款总金额",
            value: data.yester_day_money_count,
          };
          let daydataloan2 = {
            name: "今日贷款总金额",
            value: data.cur_day_money_count,
          };
          loanarr1.push(dataloan1);
          loanarr1.push(dataloan2);
          loanarr2.push(daydataloan1);
          loanarr2.push(daydataloan2);
          this.homedata.loanarr1 = loanarr1;
          this.homedata.loanarr2 = loanarr2;
          this.homedata.loan_product = productarr;
          let arr = response.data.data.plat.list;
          let arrcrowd = response.data.data.crowd;
          let arrcrowd1 = response.data.data.crowd1;
          let lines = response.data.data.plat.lines;
          for (let i = 0; i < lines.length; i++) {
            // lines[i].color = "ragb(255, 255, 255, 1)";
            lines[i].width = "0.104vw";
          }
          let item0 = arr[0];
          arr.splice(0, 1);
          let item = {
            name: item0.name,
            coordinate: item0 ? item0.coordinate : [],
            halo: {
              show: true,
              color: "#03FFDC",
            },
            text: {
              color: "#FFFFFF",
            },
          };
          console.log("item", item);

          arr.unshift(item);
          this.homedata.plat.list = arr;
          this.homedata.plat.crowd = arrcrowd;
          this.homedata.plat.crowd1 = arrcrowd1;
          this.homedata.plat.lines = lines;
          console.log("this.homedata", this.homedata); //  处理成功的函数 相当于success
        })
        .catch((response) => {
          console.log(22222); //  处理成功的函数 相当于success
          console.log(response); //  错误处理 相当于error
        });
    },
    updateDateTime() {
      this.currentDateTime = this.formatDate("YY-MM-DD hh:mm:ss");
    },

    formatDate(fmt) {
      const date = new Date();
      const o = {
        "Y+": date.getFullYear(),
        "M+": date.getMonth() + 1, // 月
        "D+": date.getDate(), // 日
        "h+": date.getHours(), // 时
        "m+": date.getMinutes(), // 分
        "s+": date.getSeconds(), // 秒
        W: date.getDay(), // 周
      };
      for (let k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(RegExp.$1, () => {
            if (k === "W") {
              // 星期几
              const week = ["日", "一", "二", "三", "四", "五", "六"];
              return week[o[k]];
            } else if (k === "Y+" || RegExp.$1.length === 1) {
              // 年份 or 小于10不加0
              return o[k];
            } else {
              return ("00" + o[k]).substr(("" + o[k]).length); // 小于10补位0
            }
          });
        }
      }
      return fmt;
    },
  },
};
</script>

<style lang="scss">
.mypage {
  width: 100%;
  // padding: 1vw 0;
  background: rgba(3, 3, 3, 1);
  .me_top {
    width: 100%;
    color: pink;
    // display: flex;
    // align-items: flex-start;
    // justify-content: space-between;
    position: relative;

    img {
      width: 1920px;
    }

    .me_tp_left {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 30px;
      top: 0vw;

      img {
        width: 24px;
      }

      span {
        font-size: 18px;
        color: #ffffff;
        margin-left: 10px;
      }
    }

    .me_tp_center {
      position: absolute;
      left: 50%;
      transform: translateX(-11.875vw);
      top: 11.5px;

      img {
        width: 456px;
      }
    }

    .me_tp_right {
      position: absolute;
      right: 23px;
      top: 0px;
      font-size: 18px;
      color: #ffffff;
    }
  }

  .me_content {
    display: flex;
    // justify-content: space-between;
    justify-content: center;
    align-items: center;
    padding-left: 35px;
    padding-right: 44px;
    margin-top: 15px;
    .me_ct_left {
      .me_ct_lt_loan {
        // margin-bottom: 1.0417vw;
      }

      .me_ct_lt_rank {
      }
    }

    .me_ct_center {
      margin-left: 95px;
      margin-top: 134px;
    }

    .me_ct_right {
      margin-left: 88px;
      .me_ct_rt_use {
        margin-top: 30px;
      }
    }
  }
}
</style>
